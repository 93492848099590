import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../components/elements/Navbar'
import components from '../content/common'
import Footer from '../components/elements/Footer'
import ContestFormFamila from '../components/elements/ContestFormFamila'

import imageVisual from '@images/contest/familaVisual.jpg'

const Headline = tw.h1`font-DIN text-white md:text-[40px] leading-[38px] text-3xl pt-5`

const Subtitle = tw.h2`font-DIN text-white md:text-[32px] leading-[38px] text-2xl pt-2`

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    ${tw`text-18px`}
    ${tw`font-bold`}

    &:before {
      content: '>';
    }

    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const Visual = styled.img``

const ContentWrap = tw.div`max-w-master mx-auto`

const DartPage = () => {
  const [confirmation, setConfirmation] = useState(false)

  return (
    <>
      <Navbar content={components.navbar} />
      <Section>
        <ContentWrap>
          <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
            <Visual src={imageVisual} />
          </div>
          <br />
          <div tw="max-w-screen-md mx-auto px-3 sm:px-7 text-center">
            <Headline>Jetzt mitmachen und gewinnen:</Headline>
            <Subtitle>1 von 20 Beerpong-Tischen</Subtitle>

            {confirmation ? (
              <p>
                Das Formular wurde erfolgreich abgesendet.
              </p>
            ) : (
              <>
                <p>
                  Lade hier Deinen Kassenbon mit mindestens einem gekauften ERDINGER Brauhaus Kasten hoch und gewinne mit etwas
                  Glück einen original ERDINGER Brauhaus Beerpong-Tisch.
                </p>
                <p>
                  Aktionszeitraum 29.07. - 10.08.2024
                </p>
                <br />
                {/*<ContestFormFamila onClick={() => setConfirmation(true)} />*/}
                <p>
                  Das Gewinnspiel ist abgelaufen, leider ist eine Teilnahme nicht mehr möglich.
                </p>
              </>
            )}
          </div>
        </ContentWrap>
      </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default DartPage
