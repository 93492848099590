import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '@context/stateContext'
import Input, { StyledMessage } from './common/ContestInput'
import { motion } from 'framer-motion'
import { Checkbox } from './common/ContestCheckbox'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const StyledForm = styled.div`
  ${tw`flex items-center h-auto sm:h-auto sm:max-h-[90vh]`}
  p {
    ${tw`font-roboto text-15px lg:text-18px text-white tracking-0.25`}
  }
`

const InputsWrapper = styled.div`
  ${tw`grid grid-cols-6 gap-x-2.5`}
`

const Button = styled.button`
  ${tw`w-[117px] h-9 rounded-[3px] font-roboto !font-normal text-15px text-white tracking-0.25 sm:text-18px sm:h-[45px] sm:w-[140px]`}
  background: #006E42 linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
`

const Cell = styled.div`
  padding-bottom: ${({ error }) => (error ? '0px' : '16px')};
`
const regExp = /\b\d{5}\b/

const link = 'https://www.erdinger-brauhaus.de/api/saveUpload.php'

const ContestForm = ({ onClick }) => {
  const { modalHandle } = useStateContext()
  const [error, setError] = useState('')

  const getFormData = (object) => {
    const formData = new FormData()
    Object.keys(object).forEach((key) => formData.append(key, object[key]))
    return formData
  }

  const onSubmit = () => {
    const formValues = {
      vorname: values.firstName,
      nachname: values.lastName,
      email: values.email,
      geburtsdatum: values.dateOfBirth,
      strasse: values.street,
      hausnummer: values.number,
      plz: values.zipCode,
      ort: values.ort,
      upload: document.querySelector('#upload').files[0],
      type: 'famila',
      newsletter: values.checkednl,
    }

    fetch(link, {
      method: 'POST',
      body: getFormData(formValues),
    })
      .then((res) => {
        return res.text()
      })
      .then((body) => {
        if (body === 'ok') {
          onClick()
        }
        if (body !== 'ok') {
          setError(body)
        }
      })
      .catch((err) => {
        setError(err)
      })
  }

  const validationSchema = Yup.object().shape({
    upload: Yup.string().required('Pflichtfeld'),
    firstName: Yup.string().required('Pflichtfeld'),
    lastName: Yup.string().required('Pflichtfeld'),
    dateOfBirth: Yup.date()
      .max(
        new Date(Date.now() - (504526816000 + 432000000)),
        'Du musst mindestens 16 Jahre alt sein'
      )
      .required('Pflichtfeld'),
    email: Yup.string().email('Ungültige E-Mail').required('Pflichtfeld'),
    street: Yup.string().required('Pflichtfeld'),
    number: Yup.string().required('Pflichtfeld'),
    zipCode: Yup.string()
      .matches(regExp, {
        message: 'Es müssen genau 5 Zahlen sein',
        excludeEmptyString: true,
      })
      .required('Pflichtfeld'),
    ort: Yup.string().required('Pflichtfeld'),
    checked: Yup.boolean().oneOf([true], 'Pflichtfeld').required(),
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      street: '',
      number: '',
      zipCode: '',
      ort: '',
      checked: false,
      checkednl: false,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit,
  })

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      tw="sm:h-auto sm:max-h-[95vh] flex items-center text-left"
    >
      <StyledForm>
        <InputsWrapper>
          <Cell tw="col-span-6" error={errors.upload}>
            <Input
              type="file"
              value={values.upload}
              onChange={handleChange('upload')}
              label="Kassenbon"
              error={errors.upload}
              id="upload"
            />
          </Cell>

          <Cell tw="col-span-3" error={errors.gender || errors.firstName}>
            <Input
              value={values.firstName}
              onChange={handleChange('firstName')}
              label="Vorname"
              error={errors.firstName}
              id="firstName"
            />
          </Cell>
          <Cell tw="col-span-3" error={errors.lastName}>
            <Input
              value={values.lastName}
              onChange={handleChange('lastName')}
              label="Name"
              error={errors.lastName}
              id="lastName"
            />
          </Cell>
          <Cell tw="col-span-4" error={errors.dateOfBirth || errors.email}>
            <Input
              value={values.email}
              onChange={handleChange('email')}
              label="E-Mail"
              error={errors.email}
              id="email"
            />
          </Cell>
          <Cell tw="col-span-2" error={errors.dateOfBirth || errors.email}>
            <Input
              value={values.dateOfBirth}
              onChange={handleChange('dateOfBirth')}
              label="Geburtsdatum"
              type="date"
              error={errors.dateOfBirth}
              id="dateOfBirth"
            />
          </Cell>
          <Cell tw="col-span-4" error={errors.street || errors.number}>
            <Input
              value={values.street}
              onChange={handleChange('street')}
              label="Straße"
              error={errors.street}
              id="street"
            />
          </Cell>
          <Cell tw="col-span-2" error={errors.street || errors.number}>
            <Input
              value={values.number}
              onChange={handleChange('number')}
              label="Hausnummer"
              error={errors.number}
              id="number"
            />
          </Cell>
          <Cell
            tw="col-span-2 sm:col-span-2"
            error={errors.zipCode || errors.ort}
          >
            <Input
              value={values.zipCode}
              onChange={handleChange('zipCode')}
              label="PLZ"
              error={errors.zipCode}
              id="zipCode"
            />
          </Cell>
          <Cell
            tw="col-span-4 sm:col-span-4"
            error={errors.ort || errors.ort}
          >
            <Input
              value={values.ort}
              onChange={handleChange('ort')}
              label="Ort"
              error={errors.ort}
              id="ort"
            />
          </Cell>
          <div tw="col-span-6 grid grid-cols-6 pt-2.5 sm:pt-0">
            <div tw="col-span-6 items-center justify-center max-h-16">
              <Checkbox
                label={
                  'Ich möchte den ERDINGER Newsletter erhalten und regelmäßig über Neuigkeiten, Veranstaltungen sowie Gewinnspiele informiert werden.'
                }
                value={values.checkednl}
                onChange={handleChange('checkednl')}
                error={errors.checkednl}
                id="newsletter"
                name="newsletter"
              />
            </div>
          </div>
          <div tw="col-span-6 grid grid-cols-6 pt-2.5 sm:pt-0">
            <div tw="col-span-4 items-center justify-center sm:col-span-6 max-h-16">
              <Checkbox
                tnb="https://www.erdinger-brauhaus.de/tnb/gewinnspiel-famila-2024"
                value={values.checked}
                onChange={handleChange('checked')}
                error={errors.checked}
                id="statute"
              />
            </div>
            <div tw="col-span-2 flex items-center justify-center sm:hidden">
              <Button onClick={handleSubmit}>abschicken</Button>
            </div>
          </div>
          <div tw="col-span-6 justify-center hidden sm:flex sm:m-2.5">
            <Button onClick={handleSubmit}>abschicken</Button>
          </div>
          <div tw="col-span-6">
            {(errors.checked || error) && (
              <StyledMessage error={errors.checked}>
                {errors.checked || error}
              </StyledMessage>
            )}
          </div>
        </InputsWrapper>
      </StyledForm>
    </motion.div>
  )
}

export default ContestForm
